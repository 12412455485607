.blog, .archive, .post-template-default {
    .main-wrap {
        display: flex;
        align-items: flex-start;
        @include breakpoint(large){
            .main-content {
                @include xy-cell(8);
            }
            .sidebar {
                @include xy-cell(4);
                padding: 2rem;
                background: #eee;

                .widget {
                    background: $white;
                    padding: 1rem;
                    margin-bottom: 1rem;
                }
            }
        }
        footer {
            margin-bottom: 2rem;
        }
    }
}

.blog, .archive {
    .post {
        display: flex;
        flex-wrap: wrap;
        img {
            flex: 1;
            object-fit: contain;
            align-self: flex-start;
            margin-right: 2rem;
        }
        .entry {
            flex: 3;
        }
        hr {
            width: 100%;
        }
    }
}