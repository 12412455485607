@keyframes alex-pop-up {
	0% { top: 100%; opacity: 1; }
	100% { top: 0; opacity: 1;}
}
// Section divider
.section-divider {
	@include xy-grid-container;
	@include xy-grid;
	background: none;

	hr {
		@include xy-cell(12);
		@extend .dotted;
		box-sizing: border-box;
	}
}

// Page header
.front-hero {
	background: transparent;
	max-height: 75vh;
	min-height: 27rem;
	overflow: hidden;
	.hero-container {
		@include xy-grid-container;
		@include xy-grid;
		> div {
			@include xy-cell(12);
			max-height: 50vh;
			@include breakpoint(medium) {
				@include xy-cell(6);
			}
		}
	}
	#video {
		display: none;
	}
	#buffer {
		display: none;
	}
	#output {
		max-width: 50%;
		position: relative;
		@include breakpoint(medium){
			max-width: none;
		}
	}
	.hero-left {
		order: 1;
		text-align: center;
	}
	@include breakpoint(medium){
		.hero-right {
			order: 1;
		}
	}
	.alex {
		position: relative;
		z-index: -1;
		top: -2rem;
		opacity: 0;
		max-width: none;
		animation-name: alex-pop-up;
		animation-duration: 0.5s;
		animation-delay: 1s;
		animation-timing-function: linear;
		animation-fill-mode: forwards;
		@include breakpoint(medium){
			top: 0;
		}
	}
	.search {
		height: 2.6rem;
		line-height: 2.6rem;
	}
	.search-box {
		background: $white;
		margin: 0;
		padding: 2rem;
		text-align: center;
		@include breakpoint(medium){
			margin: 2rem 0;
		}
		img {
			padding: 0 2rem 2rem;
			max-width: 75%;
		}
	}
}

.certified {
	background: linear-gradient(to right, darken(#DFA742, 20%) 0%, lighten(#DFA742, 20%) 10%, #DFA742 15%,  #DFA742 85%, lighten(#DFA742, 20%) 90%, darken(#DFA742, 20%) 100%);
	display: flex;
	padding: 2rem 2rem 0;
	align-content: center;
	flex-direction: column;
	@include breakpoint(large){
		padding: 1rem calc((100vw - 75rem)/2 + 2rem);
		flex-direction: row;
	}
	p {
		margin: 0;
		color: white;
		font-size: 1.5rem;
		font-weight: bold;
		line-height: 1.2;
		align-self: center;
		text-align: center;
		@include breakpoint(medium){
			font-size: 2rem;
			flex: 2;
		}
		@include breakpoint(large){
			text-align: left;
		}
	}
	img {
		max-width: 100%;
		height: auto;
		align-self: center;
		@include breakpoint(medium){
			max-width: 50%;
		}
		@include breakpoint(large){
			flex: 1;
			max-width: 15rem;
			align-self: right;
		}
	}
}

// Categories
.categories {
	background: $white;
	padding: 4rem 0;
	.category-list {
		@include xy-grid-container;
		@include xy-grid;
	}
	.category {
		@include xy-cell (1 of 3);
		text-align: center;
		margin-bottom: 1rem;
		@include breakpoint(medium){
			@include xy-cell(1 of 5);
			@include grid-column-gutter;
		}
		img {
			margin-bottom: 0;
			max-width: 5rem;
			@include breakpoint(medium){
				max-width: 8rem;
			}
		}
		img + p {
			margin-top: 1rem;
		}
		p {
			margin: 0 auto;
			font-weight: bold;
			font-size: 0.75rem;
			@include breakpoint(medium){
				max-width: 8rem;
				font-size: inherit;
			}
		}
	}
	a {
		display: block;
	}
}

// Featured Dataset
.featured {
	background: $white;
	padding: 4rem 0;
	text-align: center;

	header {
		@include xy-cell(12);

		h2 {
			font-weight: 300;
		}

		h4 {
			font-size: 1.125rem;
			line-height: 1.6;
			color: #777;
		}
	}
	.featured-story {
		@include xy-grid-container;
	}

	.description, .visualization
	 {
	 	@include xy-cell(12);
	 	text-align: left;
		@include breakpoint(medium) {
			@include xy-cell(6);
		}

		h3 {
			color: #0a0a0a;
			font-weight: 300;
			font-size: 1.75rem;
		}

		p {
			font-size: 0.9375rem;
		}
	}

	.why-foundation {
		@include xy-cell(12);
		margin-top: 4rem;
	}
}

// About Section
.about {
	background: $light-gray;
	padding: 2rem;
	text-align: center;
	.about-container {
		@include xy-grid-container;
	}
}

// Organizational Initiatives
.initiatives {
	background: $white;
	padding: 4rem 0;
	.initiative-list {
		@include xy-grid-container;
		@include xy-grid;
	}
	h2 {
		@include xy-cell(3 of 3);
		margin-bottom: 2rem;
		text-align: center;
		font-weight: 300;
	}
	.initiative {
		background: $light-blue;
		box-shadow: 4px 4px 4px $dark-gray;
		padding: 1rem;
		text-align: center;
		@include xy-cell(1 of 1, true, 4rem);
		margin-bottom: 2rem;
		padding-bottom: 4rem;
		align-content: stretch;
		@include breakpoint(medium){
			@include xy-cell(1 of 3, true, 2rem);
		}
		@include breakpoint(large){
			@include xy-cell(1 of 3, true, 4rem);
		}
		&.community {
			background: $light-blue;
		}
		&.economy {
			background: $green;
		}
		&.environment {
			background: $orange;
		}
		img {
			position: relative;
			max-width: 80%;
			top: -2rem;
			border: 4px solid $white;
			box-shadow: 4px 4px 4px $dark-gray;
		}
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
		}
		li {
			border: 4px solid $white;
			border-radius: 0.5rem;
			margin-bottom: 0.5rem;
			padding: 1rem 2rem;
			color: $white;
			line-height: 1.2rem;
			font-weight: bold;
		}
	}
}

