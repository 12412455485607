$dark-nav-color: #2C3840;
$dark-blue: rgb(32,26,84); //title color
$light-blue: rgb(59,110,143);
$green: rgb(120,162,47);
$yellow: rgb(241,203,0);
$orange: rgb(229,142,26);
$pink: rgb(103,104,103);
$red: rgb(139,14,4);
$grey: rgb(113,114,113);
$light-grey: rgb(200,200,200);
