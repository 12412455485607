video#bg { 
	position: fixed;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -1;
	-ms-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}
.main-wrap {
	@include xy-grid-container;
	@include xy-grid();
	padding: 2rem 0;

	&.sidebar-left {

		// Place the sidebar below main content on small screens ...
			@include breakpoint(small) {
				.main-content { order: 1; }
				.sidebar { order: 2; }
			}

		// ... and to the left on medium-up screens, when using the sidebar-left template
			@include breakpoint(medium) {
				.main-content { order: 2; }
				.sidebar { order: 1; }
			}
	}

	// Full width template
	&.full-width {
		.main-content {
			@include xy-cell(12);
		}
	}

	.main-content {
		@include xy-cell(12);
		&#search-results {
			@include breakpoint(medium){
				@include xy-cell(8);
			}
		}
	}

	.sidebar {
		@include xy-cell(12);
		@include breakpoint(medium) {
			@include xy-cell(4);
		}

	}
}
