.page-template-default, .page-template-datasets, .page-template-dashboard, .post-template-default, .blog, .search, .archive, .error404 {
	.container {
		min-height: 70vh;
		background: $white;
		position: relative;
	}
}
.datasets {
	border: 4px solid $dark-blue;
	font-size: 1.5rem;
	padding: 2rem 2rem 2rem 3rem;
	margin: 0 2rem 2rem 0;
	&.safety {
		border: 4px solid $light-blue;
	}
	&.growth {
		border: 4px solid $orange;
	}
	&.recreation {
		border: 4px solid $green;
	}
	&.finance {
		border: 4px solid $yellow;
	}
	&.transportation {
		border: 4px solid $red;
	}
}

.story {
	figure {
		margin: 2rem auto;
	}
	img {
		margin-bottom: 2rem;
	}
}

.page-template-dashboard {
	.main-wrap {
		padding: 0;
		@include breakpoint(medium){
			padding: 1rem 0;
		}
		article.main-content {
			margin: 0;
			width: 100%;
		}
	}
	#dash-buttons {
		text-align: center;
		button, a.button {
			display: block;
			width: 100%;
			margin: 0 0 0.25rem;
			@include breakpoint(medium){
				display: inline-block;
				width: auto;
				margin: 0 0 1rem 0;
			}
		}
		@include breakpoint(medium){
			#tab-select {
				display: none;
			}
		}
	}
	.dash-start, .dash-text {
		max-width: 75rem;
		margin: 0 auto;
	}
	#vizButtons, #frameButtons, #textButtons {
		text-align: center;
		.button {
			margin-bottom: 0.25rem;
		}
	}
	#vizContainer, #frameContainer {
		position: relative;
	}
	#vizContainer {
		min-height: 88vh;
	}
	#textContainer {
		padding-top: 2rem;
	}
	iframe {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		border: none;
		min-height: 75vh;
	}
	.section-divider, .footer-container {
		display: none;
	}
	.main-wrap {
		max-width: none;
	}
	#mobile-check {
		width: 1px;
		height: 1px;
		@include breakpoint(medium){
			display: none;
		}
	}
}

/* Tableau Dashboard Edits */
.tableauPlaceholder {
	margin: 0 auto 2rem;
	@include breakpoint(medium){
		max-width: 80%;
	}
	.page-template-dashboard & {
		margin: 0;
		max-width: none !important;

		iframe {
			top: 0;
		}
	}
}


/* Image Slider */

$bounce-in-duration: 0.7s;

.cd-image-container {
	position: relative;
	width: 90%;
	max-width: 586px; // breakpoints inside partials > _layout.scss
	margin: 0em auto;
	img {
		display: block;
	}
}

.cd-image-label {
	position: absolute;
	bottom: 0;
	right: 0;
	color: $white;
	padding: 1em;
	opacity: 0;
	transform: translateY(20px);
	-webkit-transition: -webkit-transform .3s $bounce-in-duration, opacity .3s $bounce-in-duration;
	-moz-transition: -moz-transform .3s $bounce-in-duration, opacity .3s $bounce-in-duration;
	transition: transform .3s $bounce-in-duration, opacity .3s $bounce-in-duration;

	&.is-hidden {
		visibility: hidden;
	}

	.is-visible & {
		opacity: 1;
		transform: translateY(0);
	}
}

.cd-resize-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	overflow: hidden;
	/* Force Hardware Acceleration in WebKit */
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	img {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		height: 100%;
		width: auto;
		max-width: none;
	}

	.cd-image-label {
		right: auto;
		left: 0;
	}

	.is-visible & {
		width: 50%;
		/* bounce in animation of the modified image */
		animation: cd-bounce-in $bounce-in-duration;
	}
}
@keyframes(cd-bounce-in) {
	0% {
		width: 0;
	}

	60% {
		width: 55%;
	}

	100% {
		width: 50%;
	}
}

.cd-handle {
	position: absolute;
	height: 44px;
	width: 44px;
	/* center the element */
	left: 50%;
	top: 50%;
	margin-left: -22px;
	margin-top: -22px;
	border-radius: 50%;
	background: $dark-blue url('../images/stories/slider/cd-arrows.svg') no-repeat center center;
	cursor: move;
	box-shadow: 0 0 0 6px rgba(#000, .2), 0 0 10px rgba(#000, .6), inset 0 1px 0 rgba(#fff, .3);
	opacity: 0;
	transform: translate3d(0,0,0) scale(0);

	&.draggable {
		/* change background color when element is active */
		background-color: $dark-blue;
	}

	.is-visible & {
		opacity: 1;
		transform: translate3d(0,0,0) scale(1);
		-webkit-transition: -webkit-transform .3s $bounce-in-duration, opacity 0s $bounce-in-duration;
		-moz-transition: -moz-transform .3s $bounce-in-duration, opacity 0s $bounce-in-duration;
		transition: transform .3s $bounce-in-duration, opacity 0s $bounce-in-duration;
	}
}