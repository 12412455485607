.footer-container {
  margin: 0 auto;
  background: $black;
  color: $white;
}

.footer {
	@include xy-grid-container;
	@include xy-grid;
	padding: rem-calc(30) 0;

	.copyright {
		@include xy-cell(8);
	}

	.logo {
		@include xy-cell(4);
		text-align: right;
		img {
			max-height: 3rem;
		}
	}

	.social {
		@include xy-cell(12);
		margin: 0;
	}

	a {
		color: $white;
		text-decoration: underline;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
}
