#comments {
    padding: 2rem;
    background: #eee;
    h3 {
        margin-bottom: 1rem;
    }
    ol, li {
        list-style: none;
    }
    .comment-list {
        margin: 0;
        .comment-body {
            border: 1px solid $secondary-color;
            background: $white;
            padding: 1rem 2rem;
        }
        .children {
            margin-top: 1rem;
        }
    }
    .comment-author {
        display: flex;
        margin-bottom: 1rem;

        img {
            margin-right: 1rem;
            height: initial;
            align-self: center;
        }
        cite {
            font-size: 1.25rem;
            font-weight: bold;
            color: $dark-blue;
            &:before {
                content: '';
            }
        }
        time a {
            color: $primary-color;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

#respond {
    background: #eee;
    padding: 2rem;

    #comments + & {
        padding-top: 0;
    }
    form {
        border: 1px solid $secondary-color;
        background: $white;
        padding: 1rem 2rem;
        margin-top: 1rem;
    }
    .small {
        font-size: 0.75rem;
    }
}